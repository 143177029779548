/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'

const insuranceArticles = () => {
  const insuranceArticleImages = useStaticQuery(graphql`
      query insuranceArticleImages {
        roi: file(absolutePath: {regex: "/assets/images/IncreaseROIonInnovationSpend.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        transformation: file(absolutePath: {regex: "/assets/images/DigitalTransformation101.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="spb-10 Articles_s border-bottom">
        <Row>
          <Col sm="12" lg="5" xl="4" className="titlef sp-5">
            <h2>Our insurance related news and articles</h2>
          </Col>
        </Row>
        <Row>
          <Col sm="6">
            <a href="/read/how-to-increase-roi-on-Innovation-spend">
              <div className="Articles-img">
                <Image className="img-fluid" sizes={insuranceArticleImages.roi.childImageSharp.sizes} alt="How to Increase ROI on Innovation Spend"/>
              </div>
              <div class="card-body">
                <h6 className="h3">How to Increase ROI on Innovation Spend</h6>
                <p>Innovation in business is often a two-edged sword, promising business breakthrough on the one hand but very seldom delivering on this promise.</p>
              </div>
            </a>
          </Col>
          <Col sm="6">
            <a href="/read/digital-transformation-101">
              <div className="Articles-img">
                <Image className="img-fluid" sizes={insuranceArticleImages.transformation.childImageSharp.sizes} alt="Digital Transformation 101"/>
              </div>
              <div class="card-body">
                <h6 className="h3">Digital Transformation 101</h6>
                <p>Very little is written about how to actually, meaningfully and effectively implement a sustainable digital transformation initiatives, we share our experience.</p>
              </div>
            </a>
          </Col>
        </Row>
      </Container>
    )
  }


export default insuranceArticles
