/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import './styled.css'


const InsuranceCaseStudies = () => {
  const InsuranceCaseStudiesImages = useStaticQuery(graphql`
      query InsuranceCaseStudiesImages {
        ffs: file(absolutePath: {regex: "/assets/images/FidoThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        stdbnk: file(absolutePath: {regex: "/assets/images/StandardBankThumb.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1160) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <div className="spb-10 Casestudies-s">
        <Container>
          <Row>
            <Col sm="12" lg="5" xl="4" className="titlef sp-5">
            	<h2>Our insurance related case studies</h2>
            </Col>
           </Row>
           <Row>
              <Col sm="6">
                <div className="comingsoon">
                  <div className="project-img">
                    <Image className="img-fluid" sizes={InsuranceCaseStudiesImages.ffs.childImageSharp.sizes} alt="Frank Financial Services"/>
                  </div>
                  <small class="clientname ffs">Frank Financial Services</small>
                  <h6 className="h3">
                    Fun, quick and easy online credit reports get over 160,000 checks requested in the first 6 months. 
                  </h6>
                  <p className="technologies">
                    Branding, Strategy, Product Management, Content Creation, UI/UX Design, Front-End Development, Back-End Development
                  </p>
                </div>
              </Col>
              <Col sm="6">
                <Link to="/read/standard-bank-steps-into-the-life-insurance-gap">
                  <div className="project-img">
                    <Image className="img-fluid" sizes={InsuranceCaseStudiesImages.stdbnk.childImageSharp.sizes} alt="Standard Bank"/>
                  </div>
                  <small class="clientname stndbk">Standard Bank</small>
                  <h6 className="h3">
                    A digital transformation led fully underwritten online insurance experience launched in record time.
                  </h6>
                  <p className="technologies">
                    Product Management, UI/UX Design, Front-End Development, Back-End Development, Capacity Building
                  </p>
                </Link>
              </Col>
            </Row>
        </Container>
      </div>
    )
  }


export default InsuranceCaseStudies
