/* eslint-disable */
import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Container, Row, Col } from 'react-bootstrap'
// import { Link } from 'gatsby'
import './styled.css'
import { Link } from 'gatsby'
import Image from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'

const InsuranceHowdothis = () => {
  const InsuranceHowdothisImages = useStaticQuery(graphql`
      query InsuranceHowdothisImages {
        bold: file(absolutePath: {regex: "/assets/images/industries/insurance/BeBoldTech.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        team: file(absolutePath: {regex: "/assets/images/industries/insurance/SeizeOpportunities.jpg/"}) {
          childImageSharp {
            sizes(maxWidth: 1280) {
              ...GatsbyImageSharpSizes
            }
          }
        }
      }
    `)
    return (
      <Container className="howdo_this altgrid">
        <Row className="sp-5">
          <Col>
            <h2>What we help you do</h2>
          </Col>
        </Row>
        <Row className="sp-10 altrow">
          <Col md="6" lg="5">
            <h3>Be bold with technology</h3>
            <p>
              Empowered people and well-functioning teams are the foundation for
              great product development. While processes and methodologies like
              design thinking and Agile are essential, following a tool set
              alone will not deliver meaningful digital experiences. We pay
              particular attention to people and team dynamics as the oft
              overlooked dimension in Agile and software development.
            </p>
          </Col>
          <Col md="6" lg="6" className="offset-lg-1">
            <Image className="img-fluid" sizes={InsuranceHowdothisImages.bold.childImageSharp.sizes} alt="Be bold with technology"/>
          </Col>
        </Row>
        <Row className="sp-10 altrow">
          <Col md="6" lg="6">
            <Image className="img-fluid" sizes={InsuranceHowdothisImages.team.childImageSharp.sizes} alt="Seize new opportunities"/>
          </Col>
          <Col md="6" lg="5" className="offset-lg-1">
            <h3>Seize new opportunities</h3>
            <p>The future belongs to intelligent organizations that can respond swiftly to changing markets and customer expectations. It's costly and time-intensive for insurers to change direction at pace, and shape and deliver new products and services. Urbian helps accelerate the product development learning curve for your organization through innovation-as-a-service, prototype ideation and development, and use-case accelerators to rapidly build, test, and launch experiences and applications.</p>
          </Col>
        </Row>
      </Container>
    )
  }
export default InsuranceHowdothis
